import DeleteStorage from '@/layouts/Stack/components/DeleteStorage';
import Vue from 'vue';
import modals from '@/mixins/modals';
import showErrorModal from '@/mixins/showErrorModal';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
export default {
  mixins: [modals, showErrorOpenStackModal],
  data() {
    return {
      container: '',
    };
  },
  computed: {
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
      //   return this.$store.getters('moduleStack/getS3keys');
    },
  },
  methods: {
    deleteStorage(storage) {
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'DeleteStorage',
          size: 'medium',
          component: DeleteStorage,
          closeOnBackdrop: false,
          title: this.$t('delete'),
          props: { storage: storage },
          on: {
            change: data => {
              console.log(data);
              this.container = data.storage;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            submit: () => {
              Vue.set(this.modal.footer.confirm.on.click());
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  this.deleteContainer(this.container)
                    .then(async data => {
                      console.log(data);
                      that.$modals.close();
                      this.showResModal('Контейнер успешно удалён.');
                      await this.$store.dispatch('moduleStack/fetchProjectStorages', this.s3key);
                    })
                    .catch(e => {
                      that.$modals.close();
                      this.showErrorS3(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async deleteContainer(name) {
      console.log(name);
      const params = {
        name: name,
        key: this.s3key,
      };
      await this.$store.dispatch('moduleStack/deleteS3Bucket', params);
    },
  },
};
