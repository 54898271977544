import Vue from 'vue';
import modals from '@/mixins/modals';
import NewStorage from '@/layouts/Stack/pages/Order/NewStorage';
import showErrorModal from '@/mixins/showErrorModal';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
export default {
  mixins: [modals, showErrorOpenStackModal],
  data() {
    return {
      container: '',
      typeContainer: 'private',
    };
  },
  computed: {
    s3keys() {
      return this.$store.dispatch('moduleStack/getS3StoragesKeys', this.id);
    },
  },
  methods: {
    newStorage(id) {
      // console.log(id);
      this.id = id;
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'NewStorage',
          size: 'medium',
          component: NewStorage,
          closeOnBackdrop: false,
          title: this.$t('new'),
          props: { id: id },
          on: {
            change: data => {
              this.container = data.name;
              this.typeContainer = data.type;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  // console.log(instance);
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  // if (!this.project) await this.checkProject();
                  const payload = {
                    name: this.container,
                    type: this.typeContainer,
                  };
                  this.createContainer(payload)
                    .then(async data => {
                      console.log(data);
                      that.$modals.close();
                      this.showResModal(`Контейнер <b>${this.container}</b> успешно создан.`);
                      await this.$store.dispatch(
                        'moduleStack/fetchProjectStorages',
                        this.s3keys[0]
                      );
                    })
                    .catch(e => {
                      that.$modals.close();
                      // console.log(e);
                      this.showErrorS3(e);
                    });
                },
                // console.log(e);
                // this.showError(e);
                // });
                // },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    // async checkProject() {
    //   return await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects');
    // },
    // async fetchStoragesForNew() {
    //   return this.$store.dispatch('moduleStack/fetchProjectStorages', this.project).then(data => {
    //     console.log(data);
    //   });
    // },
    // isCreatedContainer(name) {
    //   const params = {
    //     project: this.project,
    //     storage: name,
    //   };
    //   return this.$store.dispatch('moduleStack/isCreatedStorage', params);
    // },
    // async getCurrentStorage(name) {
    //   const paramsHead = {
    //     project: this.project,
    //     name: name,
    //     type: 'head',
    //   };
    //   const paramsGet = {
    //     project: this.project,
    //     name: name,
    //     type: 'get',
    //   };
    //
    //   return new Promise(async resolve => {
    //     await this.$store.dispatch('moduleStack/fetchStorages', paramsGet).then(async data => {
    //       // console.log(data);
    //       await this.$store.dispatch('moduleStack/fetchStorages', paramsHead);
    //       resolve(data);
    //     });
    //   });
    // },
    async createContainer({ name, type }) {
      const params = {
        name: name,
        type: type,
        key: this.s3keys[0],
      };
      await this.$store.dispatch('moduleStack/createS3Bucket', params);
    },
    // createContainer({ name, type }) {
    //   console.log(type);
    //   const params = {
    //     project: this.project,
    //     storage: name,
    //     type: type,
    //   };
    //   if (type === '2') return this.$store.dispatch('moduleStack/createStoragePublic', params);
    //   else return this.$store.dispatch('moduleStack/createStoragePrivate', params);
    // },
  },
};
