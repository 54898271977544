<template>
  <div class="tariffs-table">
    <base-table :columns="tableHead" :list="paginList" class="tariffs-table__table">
      <!--      @sort-change="onSortChange"-->
      <!--      :sort="sort"-->
      <tariffs-table-storage-row
        v-for="item in paginList"
        :id="id"
        :key="item.name"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="storages.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableStorageRow from './TariffsTableStorageRow';
import NewPagination from '@/components/Pagination/NewPagination';

export default {
  name: 'TariffsTableStorage',
  components: {
    BaseTable,
    TariffsTableStorageRow,
    NewPagination,
  },
  props: {
    // type: {
    //   type: Boolean,
    //   default: false,
    // },
    id: {
      type: String,
      required: true,
    },
    tableHead: {
      type: Array,
      default: () => [],
    },
    storages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stepOptions: [10, 25, 50, 100],
      pagin: {
        start: 0,
        step: 10,
      },
    };
  },
  computed: {
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.storages.slice(start, end);
    },
    showPagination() {
      return this.storages.length > this.stepOptions[0];
    },
  },
  watch: {},
  mounted() {},
  methods: {
    setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
